import { memo, useCallback, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classNames from "classnames";
import { FaucetHealthResponse } from "../libraries/FaucetRequestTypes";
import AlchemyLogoComponent from "./AlchemyLogoComponent";
import AuthCheckComponent, { AuthCheckProps } from "./AuthCheckComponent";
import FaucetHealthComponent from "./FaucetHealthComponent";
import "../../assets/css/ClassicApp.css";
import FaucetLinkComponent from "./FaucetLinkComponent";
import useServerData from "../hooks/useServerData";

export const ClassicSingleFaucetNavigationBar = memo(
  function ClassicNavigationBar({
    affiliateReferral,
    healthData,
    authData,
  }: {
    affiliateReferral: string;
    healthData?: FaucetHealthResponse;
    authData: AuthCheckProps;
  }) {
    const { APP_NETWORK_INFO, SUPPORTED_NETWORK_CONFIGS } = useServerData();

    const [selectedNetwork, setSelectedNetwork] = useState(true);
    const flipSelectedNetwork = useCallback(
      () => setSelectedNetwork((value) => !value),
      [setSelectedNetwork],
    );

    return (
      <Container
        className="alchemy-faucet-nav"
        style={{ position: "relative" }}
      >
        <Row className="alchemy-faucet-beta">
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingLeft: 10,
              alignItems: "center",
            }}
            className="alchemy-faucet-health-nav-col"
          >
            Powered by&nbsp;
            <span style={{ marginTop: 1, marginRight: 10 }}>
              <AlchemyLogoComponent affiliateReferral={affiliateReferral} />
            </span>
            {healthData && (
              <FaucetHealthComponent key="1" faucetHealth={healthData} />
            )}
          </Col>
          <Col className="alchemy-faucet-link-nav-col">
            {SUPPORTED_NETWORK_CONFIGS.map((appConfig) => {
              return (
                <FaucetLinkComponent
                  key={appConfig.id}
                  network={appConfig}
                  currentNetwork={APP_NETWORK_INFO}
                  className={classNames("alchemy-faucet-link-nav", {
                    "alchemy-faucet-link-nav-current-network":
                      APP_NETWORK_INFO.id === appConfig.id && selectedNetwork,
                  })}
                  flipSelectedNetwork={flipSelectedNetwork}
                />
              );
            })}
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: 10,
            }}
            className="alchemy-faucet-login-nav-col"
          >
            <span style={{ marginLeft: 10, marginBottom: 1 }}>
              <AuthCheckComponent {...authData} />
            </span>
          </Col>
        </Row>
      </Container>
    );
  },
);
